.dual-horizontal {
	position: sticky;
	top: 0;
	padding: 0;
	background: var(--#{$variable-prefix}primary);
	z-index: 1;
	.nav{
        background: var(--#{$variable-prefix}primary);
    }
	.navbar-nav {
		.nav-item {
			&:hover {
				.nav-link {
					&.active{
						background: var(--bs-gray-900);
						color: var(--bs-gray-600);
					}
				}
				
			}
		}
		.nav-link{
			border-radius: $border-radius-sm;
			padding: .5rem 1rem;
			color: var(--bs-white);
		}
	}
}

@media all and (max-width: 991px) {
	.horizontal-nav{
		&.mobile-offcanvas{
			$background: var(--bs-gray-900);
			.navbar-nav {
				.nav-item {
					&:not(:first-child) {
						margin-left: 0;
					}
				}
			}
		}
	}

	.dual-horizontal{
		.horizontal-nav{
			&.mobile-offcanvas{
				.nav-link{
					color: var(--bs-white);
				}
			}
		}
	}
}

@include media-breakpoint-only(md) {
	.navbar{
		.logo-center{
			&.navbar-brand{
				justify-content: center;
			}
		}
	}
}