/* Custom style for monthSelect */
.flatpickr-monthSelect-months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.flatpickr-monthSelect-month {
  width: 30%;
  margin: 5px;
  text-align: center;
  cursor: pointer;
  padding: 10px; /* Add some padding to make the hover effect more noticeable */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover effect for each month */
.flatpickr-monthSelect-month:hover {
  background-color: #007BFF; /* Change this color to what you prefer */
  color: white; /* Change text color to white for better contrast */
}
