.flatpicker-shadow-none {
    &+.flatpickr-calendar.inline {
        margin: auto;
        box-shadow: none;
    }
}

.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
    background: var(--#{$variable-prefix}primary);
    border-color:  var(--#{$variable-prefix}primary);
}
.flatpickr-calendar{
    font-size: 16px;
}
.flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg{
    fill: $dark;
}


.course-picker{
    .flatpickr-calendar{
        width: 100%;
        box-shadow: none;
    }
    .flatpickr-innerContainer{
        justify-content: center;
        .flatpickr-days{
            width: 100%;
            .dayContainer{
                width: 100%;
            }
        }
    }
    .flatpickr-months {
        .flatpickr-current-month{
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .flatpickr-next-month, .flatpickr-prev-month {
            padding: 0 0.5rem;
            color: var(--#{$variable-prefix}primary) !important;
            fill: var(--#{$variable-prefix}primary) !important;
            display: flex;
            align-items: center;
            svg{
                width: 24px;
                height: 24px;
                border: 1px solid;
                padding: 6px;
                border-radius: 50rem;
            }
            &.flatpickr-disabled{
                display: none;
            }
            &:hover svg{
                fill: var(--#{$variable-prefix}primary) !important;
            }
        }
    }
}
.course-picker .flatpickr-innerContainer .flatpickr-days .dayContainer {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));}

.flatpickr-day {
    width: 100% !important;
    margin: auto !important;
}
.flatpickr-rContainer {
    width: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: var(--bs-body-bg);
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
    background-color: var(--#{$variable-prefix}body-bg);
    color: var(--bs-body-color);
}

.flatpickr-time input {
    color: var(--bs-body-color); 
}
.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
    color: var(--bs-heading-color);
}