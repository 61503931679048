//backgroung transition
.hvr-border-fade:hover, .hvr-border-fade:focus, .hvr-border-fade:active {
    box-shadow: inset 0 0 0 4px var(--#{$variable-prefix}primary-bg-subtle), 0 0 1px;
}
.hvr-back-pulse {
    background-color: var(--#{$variable-prefix}primary-bg-subtle); 
}
.hvr-sweep-to-right:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-sweep-to-left:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-sweep-to-bottom:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-sweep-to-top:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-sweep-to-top:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-bounce-to-right:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-bounce-to-left:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-bounce-to-bottom:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-bounce-to-top:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}

.hvr-rectangle-out:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-shutter-out-horizontal:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-radial-out:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-shutter-out-vertical:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}

.hvr-rectangle-out, .hvr-radial-out, .hvr-shutter-out-horizontal, .hvr-shutter-out-vertical{
    background: var(--#{$variable-prefix}primary) !important;
}


//border transition
.hvr-underline-from-left:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-underline-from-center:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-underline-from-right:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-reveal:before {
    border-color:  var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-reveal:active {
    background: var(--#{$variable-prefix}primary-bg-subtle);
    border-color:  var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-underline-reveal:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-overline-reveal:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-overline-from-left:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-overline-from-center:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-overline-from-right:before {
    background: var(--#{$variable-prefix}primary-bg-subtle);
}
.hvr-trim:before {
    border: var(--#{$variable-prefix}primary-bg-subtle) solid 4px;
}
.hvr-ripple-out:active {
    border: var(--#{$variable-prefix}primary-bg-subtle) solid 4px;
}
.hvr-outline-out:active {
    border: var(--#{$variable-prefix}primary-bg-subtle) solid 4px;
}
.hvr-ripple-in:before {
    border: var(--#{$variable-prefix}primary) solid 4px;
    border-radius: 6px;
}
.hvr-outline-in:before {
    border:var(--#{$variable-prefix}primary) solid 4px; 
    border-radius: 6px;
}
.hvr-ripple-out:before {
    border: var(--#{$variable-prefix}primary) solid 4px;
    border-radius: 6px;
}
.hvr-outline-out:before {
    border-color: var(--#{$variable-prefix}primary) solid 4px;
    border:var(--#{$variable-prefix}primary) solid 4px;
    border-radius: 6px;
}
.hvr-shadow {
    border: var(--#{$variable-prefix}primary-bg-subtle) ;
}
.hvr-glow {
    border: var(--#{$variable-prefix}primary-bg-subtle) ;
}

//speech bubble
.hvr-bubble-top:before {
    border-width: 0 8px 8px 8px;
}
.hvr-bubble-right:before {
    border-width: 8px 0 8px 8px;
}
.hvr-bubble-bottom:before {
    border-width: 8px 8px 0 8px;
}
.hvr-bubble-left:before {
    border-width: 8px 8px 8px 0;
}
.hvr-bubble-float-top:before{
    border-width: 0 8px 8px 8px;
}
.hvr-bubble-float-right:before {
    border-width: 8px 0 8px 8px;
}
.hvr-bubble-float-bottom:before {
    border-width: 8px 8px 0 8px;
}
.hvr-bubble-float-left:before {
    border-width: 8px 8px 8px 0;
}
.hrv-curl{
    .btn{
        &:hover, &:focus, &:active {
            border:unset;
            border-color: unset !important;
            box-shadow: unset !important;
        }
    }
}

.iq-plugins-btn{
    .btn{
        margin: 0 1rem 1rem 0;
    }
}
.size-28{
    width: 22px;
}