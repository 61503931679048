.table {
    thead {
        white-space: nowrap;
        tr {
            background-color: var(--bs-body-bg);
            th {
                font-weight: 500;
                text-transform: capitalize;
                letter-spacing: $table-th-letter-spacing;
                background-color: var(--bs-body-bg);
            }
        }
    }
    tbody {
        tr {
            td {
               vertical-align: middle;
                .iq-sub-label{
                    margin-bottom: 0.125rem;
                }
            }
        }
    }
    &.table-dark{
        tbody {
            tr {
                td {
                   color: var(--bs-body-bg);
                   vertical-align: middle;
                }
            }
        }
    }
    overflow: hidden;
}
.table > :not(:last-child) > :last-child > * {
    border-bottom-color: transparent;
}

.table-responsive{
    &::-webkit-scrollbar { width: 10px; height: 10px; border-radius: 20px; }
	/* Scroll Track */
	&::-webkit-scrollbar-track { background: var(--bs-body-bg); border-radius: 20px; }
	/* Scroll Handle */
	&::-webkit-scrollbar-thumb { background: rgba($dark,0.2); border-radius: 20px; transition: all 400ms ease; cursor: pointer; }
	/* Scroll Handle on hover */
	&::-webkit-scrollbar-thumb:hover { background: rgba($dark,0.2); border-radius: 20px;transition: all 400ms ease; cursor: pointer; }
    .dataTables_wrapper{
        .row{
            .dataTables_length{
                padding-left: $spacer * 1.5;
            }
            .dataTables_filter, .dataTables_paginate{
                padding-right: $spacer * 1.5;
            }
            .dataTables_info{
                padding-left: $spacer * 1.5;
                padding-top: unset;
            }
        }
    }
}

.table-dark{
    td {
        color: var(--bs-body-color) !important;
        vertical-align: middle;
    }
}

thead, tbody, tfoot, tr, td, th{
    white-space: nowrap;
}
  
@include media-breakpoint-down(xl) {
    .table-responsive{
        .dataTables_wrapper{
            .row{
                .dataTables_length{
                   margin-bottom: 1em;
                }
            }
        }
    }
}